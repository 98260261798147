<script setup lang="ts">
/**
 * For login with sso, we render html a-tags via nuxt-link, because this is semantically correct.
 * For registration with sso, we can't use links directly, because the parent component has to
 * perform some actions before the redirect can occur. Therefore we use buttons instead and
 * emit the chosen sso provider information to the parent.
 */

import type { SSOUrls } from '~/models/Registration';

const emit = defineEmits(['click']);

const { t } = useI18n({
  useScope: 'local',
});

defineProps({
  ssoUrls: {
    type: Object as PropType<SSOUrls>,
    required: true,
  },
  type: {
    type: String,
    default: 'login',
  },
});
</script>

<template>
  <div class="wrapper">
    <ul class="buttons">
      <li v-for="(value, key) in ssoUrls" :key="key">
        <template v-if="type === 'login'">
          <nuxt-link :to="value" :class="['button', 'button-' + key]" :title="t('login-' + key)">
            <Icon :name="`bi:${key}`" class="icon" />
          </nuxt-link>
        </template>
        <template v-else>
          <button type="button" :class="['button', 'button-' + key]" :title="t('register-' + key)" @click="emit('click', { provider: key, link: value })">
            <Icon :name="`bi:${key}`" class="icon" />
          </button>
        </template>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.wrapper {
  width: 100%;
  margin: 1rem 0;
}

.buttons {
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include tw-is-desktop {
    gap: 1rem;
  }
}

.button {
  width: 100px;
  display: flex;
  padding: 1rem 0;
  border-width: 1px;
  align-items: center;
  border-style: solid;
  border-radius: 0.2rem;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.button-facebook {
  outline: none !important;
  @include tw-color-set($colorFacebook, $white, $colorFacebook);

  &:hover {
    @include tw-color-set($white, $colorFacebook, $colorFacebook);
  }
}

.button-google {
  outline: none !important;
  @include tw-color-set($colorGoogle, $white, $colorGoogle);

  &:hover {
    @include tw-color-set($white, $colorGoogle, $colorGoogle);
  }
}

.button-apple {
  outline: none !important;
  @include tw-color-set($colorApple, $white, $colorApple);

  &:hover {
    @include tw-color-set($white, $colorApple, $colorApple);
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  @include tw-is-desktop {
    width: 3rem;
    height: 3rem;
  }
}
</style>

<i18n lang="json">
{
  "de": {
    "login-facebook": "Mit Facebook anmelden",
    "register-facebook": ";Mit Facebook registrieren",
    "login-google": "Mit Google anmelden",
    "register-google": "Mit Google registrieren",
    "login-apple": "Mit Apple anmelden",
    "register-apple": "Mit Apple registrieren"
  },
  "en": {
    "login-facebook": "Login with Facebook",
    "register-facebook": "Register with Facebook",
    "login-google": "Login with Google",
    "register-google": "Register with Google",
    "login-apple": "Login with Apple",
    "register-apple": "Register with Apple"
  }
}
</i18n>
